import * as Yup from 'yup';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email required"),

  message: Yup.string().optional(),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("ZipCode is required"),
  bussinessName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid bussinessName")
    .required("bussinessName name required"),
  bussinessAddress: Yup.string()
    .matches(/^[A-Za-z0-9\s\.,#@\-]+$/, "Please enter valid bussinessAddress")
    .required("bussinessAddress name required"),
  companySize: Yup.string().required('Please select company size'),
  fleetSize: Yup.string().required('Please select fleet size'),
  fuelType: Yup.string().required('Please select fuel type')
})

export default Schema;